import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import InstagramEmbed from 'react-instagram-embed'
import MariekiFabriek from '../assets/images/SIP/MarienkiFabriek.jpg'

import BeautyWithinOutside from '../assets/images/SIP/BeautyWithinOutside.jpg'

import Carousel from '../components/ProductsCarousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
class SIP extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  render() {
    const siteTitle = 'hanlie claasen interiors'

    return (
      <Layout ishome={false}>
        <Helmet title={siteTitle} />
        <header className="major main special">
          <h1>Small Interior Products</h1>
        </header>
        <section id="Marienki" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>Marienki</h2>
              </header>
              <p>
                Being a Home Economics Graduate with a passion for excellent
                workmanship, I had a dream and ideal to create my own interior
                product workshop. In 2000 my paths crossed with an unemployed
                seamstress, Marienki, and I decided to offer her an opportunity
                by teaching her the curtain and softs trade to sustain her
                family. During the interview, we hit the process running by
                doing a practical demonstration of how to insert a zip. Shortly
                thereafter we had a curtain making workshop in my garage.
                Marienki was a keen student and showed great potential.
              </p>
            </div>
            <div className="col-6">
              <span class="image fit">
                <img src={MariekiFabriek} alt="Hanlie and Marienki" />
              </span>
            </div>
          </div>
        </section>

        <section id="beautywithin" className="main style1 special">
          <div className="grid-wrapper">
            <div className="col-6">
              <span class="image fit">
                <img
                  src={BeautyWithinOutside}
                  alt="Entrance to Beauty Within"
                />
              </span>
            </div>
            <div className="col-6">
              <header className="major">
                <h2>Beauty Within</h2>
              </header>
              <p>
                Almost 19 years ago I met Izaan, a kind-hearted lady referring
                clients to me, without even knowing me. Ever since we have been
                acquaintances. Izaan wanted to move her business, Beauty Within,
                to greener pastures, and I saw it as the perfect opportunity to
                sell small interior products – creating an opportunity to keep
                Marienkie busy.
              </p>
            </div>
          </div>
        </section>
        <section id="theproduct" className="main style1 special">
          <div className="grid-wrapper">
            <div className="col-12">
              <header className="major">
                <h2>The Products</h2>
              </header>
              <Carousel />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SIP
