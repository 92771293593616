import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';

import img01 from '../assets/images/SIP/Products/20210830_141602.jpg'
import img02 from '../assets/images/SIP/Products/20210831_111855.jpg'
import img03 from '../assets/images/SIP/Products/20210831_112511.jpg'
import img05 from '../assets/images/SIP/Products/20210831_111912.jpg'
import img06 from '../assets/images/SIP/Products/20210831_112727.jpg'
import img09 from '../assets/images/SIP/Products/20210831_112741.jpg'
import img13 from '../assets/images/SIP/Products/20210831_111220.jpg'
import img14 from '../assets/images/SIP/Products/20210831_112112.jpg'
import img15 from '../assets/images/SIP/Products/20210831_112829.jpg'
import img18 from '../assets/images/SIP/Products/20210831_112840.jpg'
import img19 from '../assets/images/SIP/Products/20210831_111406.jpg'
import img20 from '../assets/images/SIP/Products/20210831_112153.jpg'
import img22 from '../assets/images/SIP/Products/20210831_111538.jpg'
import img23 from '../assets/images/SIP/Products/20210831_112213.jpg'
import img24 from '../assets/images/SIP/Products/20210831_112919.jpg'
import img25 from '../assets/images/SIP/Products/20210831_111605.jpg'
import img26 from '../assets/images/SIP/Products/20210831_112223.jpg'
import img27 from '../assets/images/SIP/Products/20210831_113019.jpg'
import img28 from '../assets/images/SIP/Products/20210831_111619.jpg'
import img29 from '../assets/images/SIP/Products/20210831_112235.jpg'
import img30 from '../assets/images/SIP/Products/20210831_113143.jpg'
import img32 from '../assets/images/SIP/Products/20210831_112301.jpg'
import img37 from '../assets/images/SIP/Products/20210831_112427.jpg'

const images = [
  {
    original: img01,
    description: "The friendly front door of Beauty Within’s new premises"
  },
  {
    original: img22,
    description: "The friendly front door of Beauty Within’s new premises"
  },
  {
    original: img02,
    description: "A cosy corner in the waiting area. The paint-finished display unit makes it more eclectic in the modern environment."
  },
  {
    original: img30,
    description: "A cosy corner in the waiting area. The paint-finished display unit makes it more eclectic in the modern environment."

  },
  {
    original: img03,
    description: "New ducoed display units alongside the paint-finished armoire combines old worldly with contemporary storage space"
  },
  {
    original: img13,
    description: "New ducoed display units alongside the paint-finished armoire combines old worldly with contemporary storage space"

  },
  {
    original: img14,
    description: "New ducoed display units alongside the paint-finished armoire combines old worldly with contemporary storage space"

  },
  {
    original: img05,
    description: "An inviting waiting area which combines all elements – old and new - to convey a welcoming atmosphere"
  },
  {
    original: img06,
    description: "The display of all the s i p (small interior products) cushions; table cloths; backpacks; candle holders; placemats etc"
  },
  {
    original: img09,
    description: "The display of all the s i p (small interior products) cushions; table cloths; backpacks; candle holders; placemats etc"

  },
  {
    original: img15,
    description: "The display of all the s i p (small interior products) cushions; table cloths; backpacks; candle holders; placemats etc"

  },
  {
    original: img18,
    description: "The display of all the s i p (small interior products) cushions; table cloths; backpacks; candle holders; placemats etc"

  },
  {
    original: img19,
    description: "Paint-finished old worldly bench with a beautiful scatter offers a welcome at the entrance of the salon."
  },

  {
    original: img20,
    description: "Beauty Within offers a gift-service; beautifully wrapped"
  },
  {
    original: img22,
    description: "Beauty Within offers a gift-service; beautifully wrapped"
  },
  {
    original: img24,
    description: "Beauty Within offers a gift-service; beautifully wrapped"
  },
  {
    original: img25,
    description: "Beauty Within offers a gift-service; beautifully wrapped"
  },
  {
    original: img27,
    description: "Beauty Within offers a gift-service; beautifully wrapped"
  },
  {
    original: img28,
    description: "Beauty Within offers a gift-service; beautifully wrapped"
  },
  {
    original: img32,
    description: "Beauty Within offers a gift-service; beautifully wrapped"
  },
  {
    original: img23,
    description: "The detail of a scatter cushion adds all the interest that is needed to stimulate the senses."
  },
  {
    original: img26,
    description: "The detail of a scatter cushion adds all the interest that is needed to stimulate the senses."
  },
  {
    original: img29,
    description: "The detail of a scatter cushion adds all the interest that is needed to stimulate the senses."
  },
  {
    original: img37,
    description: "Where you are spoiled..."
  },
  ]
export default class LazyLoad extends Component {
    render() {
      return <ImageGallery infinite={true} autoPlay={true} items={images} lazyLoad={true}/>;
    }
}
